@use "sass:math";
.subtemplate-side_graphic {
    display:flex; 
    flex-direction: row;   
    .subpage-image, .subpage-content {
        flex-basis:50%;
    }
    .subpage-image {
        background-position: center;
        background-size: cover;
        img {
            object-fit: cover;
            object-position: center;
            height: 100% !important;
            width: 100%;
        }
    }
    .subpage-content {
        background-color: $secondary;
        color: white;
        padding: 5em 0;
        box-sizing: border-box;
        .subpage-contentwrap {
            max-width: math.div($body_width, 2);
            padding-left:5em;
            padding-right:1em;
            box-sizing: border-box;
        }
    }
}
.subtemplate-side_graphic:nth-of-type(2n+1) {
    .subpage-image {
        order:1;
    }
    .subpage-content {
        background-color: $tertiary;
        .subpage-contentwrap {
            margin-left: auto;
            margin-right: 0;
            padding-left: 30px;
            padding-right:5em;
        }
    }
}
@media only screen and (max-width: 760px){
    .subtemplate-side_graphic,
    .subtemplate-side_graphic:nth-of-type(2n+1) { 
        display: block;
        .subpage-image {
            height: 50vh;
        }
        .subpage-content .subpage-contentwrap {
            margin: 0 auto;
            margin-right:auto;
            padding:0 30px;
        }
    }
}