
/*============ splash ============*/
/*  banner */
.home-banner {
    position: relative;
    display: flex;
    min-height: 80vh;
    align-items: center;
    justify-content: center;
    padding: 4em 0;
    box-sizing: border-box;
    min-height: 100vh;
    .page-graphic img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100% !important;
        width: 100%;
        object-fit: cover;
        object-position: top;
        z-index: -1;
    }
    .call-to-action { 
        color:#ffffff;
        position: absolute;
        bottom:2em;
        left:0;
        right:0;
        text-align: center;
    }
    .page-icon {
        max-width: 360px;
        padding:10px;
    }
}
.home-banner, nav.main.feature {
    .call-to-action::before {
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
    }
}
nav.main.feature {
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 75vh;
        position: relative;
        overflow:hidden;
        margin:1em 0;
        .menu-graphic-container::after {
            content:"";
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom: 0;
            mix-blend-mode: multiply;
            background-image: linear-gradient(rgba($darken1,0.5), $darken2);
            opacity: 54%;
            transition: opacity ease 0.3s;
        }
        .menu-graphic-container {
            transition: transform ease 0.3s;
            img {
                height: 100% !important;
                width: 100%;
                object-fit:cover;
                object-position: center;
            }
        }
        .menu-graphic-container, .menu-graphic {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: -1;
        }
        .call-to-action {
            position: absolute;
            bottom:2em;
            left:0;
            right:0;
            color:#ffffff;
            text-align: center;
        }
        .title {
            font-size:60px;
            color:#ffffff;
        }
        &:hover {
            .menu-graphic-container {
                transform: scale(1.1);
            }
            .menu-graphic-container::after {
                opacity: 100%;
            }
        }
    }
}
@media only screen and (max-width: 760px) {
    nav.main.feature li .title {
        font-size:40px;
    }
}
@media only screen and (max-height: 500px) {
    nav.main.feature li {
        .call-to-action {
            bottom:0;
        }
    }
}
@media only screen and (max-height: 350px) {
    nav.main.feature li {
        .call-to-action {
            bottom:0;
            margin-bottom:0;
            &::before {
                margin-bottom:2px;
            }
        }
    }
}