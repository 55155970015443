/** menu generic **/
nav {
    ul {
        list-style:none;
        padding: 0;
        margin: 0;
    }
    li {
        display: inline-block;
        box-sizing: border-box;
        &.active {
            color:$secondary;
        }
        a {
            display: block;
            color:inherit;
            text-decoration: none;
            &[href$='.pdf'] { /* overrides pdf style in editor css */
                border-bottom: none;
                margin:0 1em;
                padding-bottom:0;

            }
        }
        &.top {
            > a  {
                > span.title {
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
        li {
            display: block;
        }
    }
}