/*============ Contact ============*/
.contact-wrapper {
    background-color: $quaternary;
    color:#000000;
    padding:5vw 0;
    .tertiary {
        color:#266c64;
    }
    .contentwrap {
        display: flex;
        flex-direction: row;
        .subpage-content {
            flex-basis:40%;
            flex-shrink: 0;
            padding-right:1em;
            margin-bottom:30px;
        }
        form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            p {
                flex-basis: 48%;
                margin: 1%;
                &.full {
                    flex-basis:100%;
                }
            }
            .button {
                width: auto;
            }
        }
    }
}
@media only screen and (max-width: 760px) {
    .contact-wrapper {
        .contentwrap {
            flex-direction: column;
        }
    }
}
@media only screen and (max-width: 500px) {
    .contact-wrapper {
        .contentwrap {
            form {
                p {
                    flex-basis: 100%;
                }
            }
        }
    }
}