table {
    border-spacing: 10px;
    width:100%;
    tbody tr:first-of-type td{
        border-top:2em solid transparent;
    }
    th {
        text-align: left;
        padding:1em 0;
        border-bottom: 2.5px solid black;
    }
    td {
        border-bottom: 1px solid black;
        max-width: 450px;
    }
}
.responsive-table-wrap {
    overflow-x: auto;
    &.small-mode {
        overflow-x: hidden;
		/* stack all the cells */
        table,
        tr,
        td {
            display: block;
        }
        td {
            padding: 1em 0;
        }
		/* put some space between each row block */
        tr {
            padding: 0.7em 2vw;
            margin: 1em auto;
        }
		/* add zebra striping to make it more obvious where each element starts and ends */
        tr:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.25);
        }
        tr:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.5);
        }
        
        /* Hide original table headers */
        th,
        tr:first-of-type {
            display: none;
        }

		/* give the pseudo element labels a little extra weight to stand out */
		td::before {
			display: block;
			font-weight: bold;            
		}
        
    }
}