@import 'side_graphic';
@import 'image_bg';

.subtemplate-blue_bg {
    background-color: $quaternary;
    padding: 1px 0;
}
.subtemplate-image_bg {
    .subpage-bg {
        min-height: 100vh;
        background-position: center;
        background-size: cover;
        img {
            height: 100% !important;
            max-width: none;
            object-fit: cover;
            width:100%;
        }
    }
    .with-content .subpage-bg {
        min-height: 0;
    }
}
.subpage-content {
    .title {
        font-size:18px;
    }
    .subtitle {
        font-size:60px;
        margin:15px 0;
    }
    a {
        color:inherit;
        text-decoration: none;
    }
}
.subpage-wrap {
    min-height: 50vh;
    &.with-bg {
        min-height: 0;
    }
    .subpage-content {
        position: relative;
        padding:5em;
        h2 {
            font-size: 60px;
        }
    }
}

@media only screen and (max-width: 960px){
    .subpage-content .subtitle {
        font-size:4vw;
    }    
    .subpage-wrap .subpage-content h2 {
        font-size:32px;
    }
    .subpage-wrap {
        min-height: 25vh;
        &.with-bg {
            min-height: 0;
        }
    }
    .subtemplate-image_bg .subpage-bg {
        min-height: 25vh;
    }
}