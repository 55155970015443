/** form generic **/
form {
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;

}
label.float,
input {
    height: 50px;
    line-height: 50px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font; 
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    border:none;
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap {
    position: relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 1;
    font-size: 0.75em;
}
form button {
    /* text-transform: uppercase;*/
    width: 100%;
    cursor: pointer;
}