/*============ header ============*/

a.logo {
    display: inline-block;
    width: 250px;
    height:auto;
}
header .contentwrap {
    padding: 0 30px;
}
header .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
header nav li a {
    padding: 1em;

}
@media only screen and (max-width: 960px) {
    .header-menu,
    .main-menu {
        display:none;
    }
}