/*============ sub page============*/
.page-banner {
    text-align: center;
    .page-title {
        font-size:20px;
        font-weight: bold;
        color:#ffffff;
        background-color: $tertiary;
        display: inline-block;
        padding:0.5em;
        text-transform: uppercase;
    }
    .subtitle {
        font-size:50px;
        font-weight: bold;
        margin: 0;
    }
    .contentwrap {
        max-width: 960px;
    }
    &.with-bg {
        position: relative;
        color:#ffffff;
        .page-graphic {
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-size:cover;
            background-position:center;
            position:absolute;
            display:block;
            mix-blend-mode: multiply;
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100% !important;
                object-fit: cover;
            }
            &::after {
                content:"";
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background-color: rgba(0,0,0,0.51);
            }
        }
        padding-top:10vh;
        padding-bottom:20vh;
        &::before {
            content:"";
            position: absolute;
            bottom:0;
            height:20vh;
            left:50%;
            border-left:1px solid white;
            z-index: 10;
        }
    }
}
.page-body {
    background-color: #437795;
    color: #ffffff;
    font-size:24px;
    text-align: center;
    .contentwrap {
        max-width: 960px;
    }
}
@media only screen and (max-width: 960px){
    .page-banner .subtitle {
        font-size: 5vw;
    }
}