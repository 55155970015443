.subtemplate-image_bg .subpage-wrap {
    &.with-content {
        padding:5em;
        position: relative;        
        .subpage-content {        
            background:#ffffff;
            color:#000000;
            position: relative;
            z-index: 1;
            mix-blend-mode: normal;
        }   
        .subpage-contentwrap {
            max-width: 450px;
            margin: 0 auto;
            text-align: center;
        }
        .subpage-bg {
            mix-blend-mode: multiply;
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            &::before {
                content:"";
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background-color: rgba(0,0,0,0.7);
            }
        }
    }
}
@media only screen and (max-width: 760px){
    .subtemplate-image_bg .subpage-wrap.with-content {
        padding:2em;
        .subpage-content {
            padding:2em;
        }
    }
}