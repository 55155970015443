
header,
footer {
    color: #FFF;
    background-color: #000;
    font-size: 14px;
    nav {
        li.top {
            margin: 2px 5px;
        }
    }
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
@import "header";
@import "footer";