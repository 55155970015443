
/*============ footer ============*/
footer {
    .flex {
        display: flex;
        justify-content: space-between;
    }
    .footer-top {
        background-color: $dark;
        padding-top:15em;
        padding-bottom: 15em;
        border-bottom:1px solid white;  
        nav > ul > li {
            margin:1em;
            vertical-align: top;
        }
        nav li.top > a {
            margin-bottom:1em;
        }
        .additional_logos img {
            margin:0 0.5em;
        }
    }
    .footer-bottom {
        background-color:#000000;
        /*background:#000000 url('/app/img/footer-bg.jpg') center no-repeat;*/
        background-size:cover;
        color:#fff;
        .col {
            display: flex;
            align-items: center;
        }
        li a {
            margin: 0 1em;
        }
    }
    .additional_logos {
        margin-top:2em; 
    }
}
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding-left:30px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}
@media only screen and (max-width: 760px) {
    footer .flex {
        display: block;
    }
}