.testimonials {
    background-color: $tertiary;
    color:#ffffff;
}
.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        margin:1%;
        padding:1em;
        background-color: #ffffff;
        color: $txt;
        text-align: center;
        flex-basis: 30%;
        box-sizing: border-box;
        min-width: 250px;
        .icon {
            width:100px;
            height: 100px;
            background-position: center;
            background-size: contain;
            border-radius: 50%;
            margin: 0 auto;
        }
        .title {
            font-weight: normal;
            font-size:1.2em;
        }
        .organisation {
            font-weight: bold;
            font-size: 0.8em;
        }
    }
}
.rating {
    .star, .hollow-star {
        display: inline-block;
        width:32px;
        height:32px;
        background-position: center;
        background-size: contain;
    }
    .star {        
        background-image: url('../assets/solid-star.svg');
    }
    .hollow-star {
        
        background-image: url('../assets/hollow-star.svg');
    }
}
@media only screen and (max-width: 960px) {
    .list {
        display: block;
        .item {
            max-width: 500px;
            margin: 1em auto;
        }
    }
}